<template>
  <div class="room-log-container">
    <!--! Header row -------------------------------------------------------------------------------->
    <b-row class="row-search">
      <div class="list-size">
        <v-select
          v-model="querySearch.size"
          :clearable="false"
          :options="options"
          @input="searchListRoomLog"
        />
      </div>
      <FloatInput :hideInput="true" :isBreadcrumb="true">
        <template #before-input>
          <feather-icon icon="CalendarIcon" size="25" class="flatpickr-icon" />
          <flat-pickr
            style="min-width: 150px; max-width: 220px; padding-left: 40px"
            name="flatpickr"
            class="form-control"
            input-class="md-input"
            :config="{
              mode: 'range',
              dateFormat: 'Y/m/d',
              clickOpens: true,
              defaultDate: [querySearch.start_date, querySearch.end_date],
              locale: { rangeSeparator: ' ~ ' },
            }"
            v-model="dateRange"
            @input="handleSearch"
          >
          </flat-pickr>
          <b-button @click="searchListRoomLog" variant="outline-primary" class="btn btn-primary btn-search">
            <feather-icon icon="SearchIcon" size="14"> </feather-icon>
          </b-button>
          <b-button
            style="height: 38px; min-width: 160px; margin-left: 20px"
            variant="outline-primary"
            @click="exportCsvRoomLog"
            >CSVダウンロード</b-button
          >
        </template>
      </FloatInput>
      <p class="mb-0">
        「※日毎のルーム参加・視聴ユーザーの参加時間合計」
      </p>
    </b-row>
    <!--! Table row -------------------------------------------------------------------------------->
    <b-row>
      <b-col class="table-limit-height">
        <b-table
          hover
          :items="list"
          :fields="header"
          show-empty
          thead-tr-class="header-split"
          tbody-tr-class="table-white-line"
        >
          <template v-for="item in header" v-slot:[`head(`+item.key+`)`]="data">
            <div
              :style="'width:' + data.field.width"
              class="header-table"
              :key="item.key"
            >
              <span class="text-center">{{ data.label }}</span>
            </div>
          </template>
          <template #thead-top>
            <b-tr>
              <b-th colspan="3" class="set-border"></b-th>
              <b-th colspan="3" class="set-border text-center">
                ルーム参加者数
              </b-th>
              <b-th colspan="3" class="set-border text-center"
                >ルーム視聴者数</b-th
              >
              <b-th class="set-border"></b-th>
              <b-th colspan="3" class="set-border text-center"
                >当日無料アクティブユーザー</b-th
              >
              <b-th colspan="3" class="set-border text-center"
                >当日有料アクティブユーザー</b-th
              >
            </b-tr>
          </template>
          <template #cell(report_date)="data">
            {{ formatDate(data.item.report_date, "yyyy/MM/DD") }}
          </template>
          <template #empty>
            <p class="text-center">データが見つかりません。</p>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <div class="pagination float-right">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.total"
          :per-page="pagination.perPage"
          @change="changePage"
        ></b-pagination>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BButton,
  BCol,
  BTable,
  BTr,
  BTh,
  BPagination,
} from "bootstrap-vue";
import FloatInput from "@/@core/components/float-input/FloatInput.vue";
import flatPickr from "vue-flatpickr-component";
import { apiService } from "@/services/api.service";
import { STATUS } from "@/constant/constant";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  name: "RoomLog",
  components: {
    BRow,
    FloatInput,
    BButton,
    BCol,
    BTable,
    BTr,
    BTh,
    BPagination,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      header: [
        { label: "日付", key: "report_date", value: "", width: "150px" },
        { label: "ルーム数", key: "total_room", value: "", width: "100px" },
        {
          label: "配信時間合計",
          key: "total_delivery_time",
          value: "",
          width: "120px",
        },

        {
          label: "最小",
          key: "quantity_room_member.min",
          value: "",
          width: "100px",
        },
        {
          label: "平均",
          key: "quantity_room_member.average",
          value: "",
          width: "100px",
        },
        {
          label: "最大",
          key: "quantity_room_member.max",
          value: "",
          width: "100px",
        },

        {
          label: "最小",
          key: "quantity_viewer.min",
          value: "",
          width: "100px",
        },
        {
          label: "平均",
          key: "quantity_viewer.average",
          value: "",
          width: "100px",
        },
        {
          label: "最大",
          key: "quantity_viewer.max",
          value: "",
          width: "100px",
        },

        {
          label: "配信時間積算計(※)",
          key: "delivery_time",
          value: "",
          width: "120px",
        },

        {
          label: "合計",
          key: "total_active_free_user.total",
          value: "",
          width: "100px",
        },
        {
          label: "男性",
          key: "total_active_free_user.male",
          value: "",
          width: "100px",
        },
        {
          label: "女性",
          key: "total_active_free_user.female",
          value: "",
          width: "100px",
        },

        {
          label: "合計",
          key: "total_active_paid_user.total",
          value: "",
          width: "100px",
        },
        {
          label: "男性",
          key: "total_active_paid_user.male",
          value: "",
          width: "100px",
        },
        {
          label: "女性",
          key: "total_active_paid_user.female",
          value: "",
          width: "100px",
        },
      ],
      list: [],
      pagination: {
        currentPage: 1,
        total: 0,
        perPage: 25,
      },
      querySearch: {
        page: 1,
        size: 25,
        start_date: '',
        end_date: '',
      },
      dateRange: "",
      options: [10, 25, 50],
    };
  },
  created() {
    this.handleSearch();
    this.getCurrentMonth();
  },
  mounted() {
    this.onGetRoomLog();
  },
  computed: {
    queryRoute() {
      return this.$route.query;
    },
  },
  watch: {
    querySearch() {
      console.warn(this.querySearch.start_date );
      if(this.querySearch.start_date !== this.querySearch.end_date ) {
          this.onGetRoomLog();
      }
    },
  },
  methods: {
    /* <!--@--> (handleSearch): Handle search function ------------------------------------------------------------------------- */
    handleSearch() {
      if (this.dateRange) {
        this.querySearch.start_date = this.dateRange.split(" ~ ")[0];
        this.querySearch.end_date = this.dateRange.split(" ~ ")[1];
        if (!this.querySearch.end_date) {
          this.querySearch.end_date = this.dateRange.split(" ~ ")[0];
        }
      } else {
        this.querySearch.start_date = '';
        this.querySearch.end_date = '';
      }
      this.querySearch.page = 1;
      // this.onGetRoomLog();
    },
    getCurrentMonth() {
      this.querySearch.start_date = moment().startOf('month').format('yyyy/MM/DD'),
      this.querySearch.end_date = moment().endOf('month').format('yyyy/MM/DD')
    },
    searchListRoomLog() {
      this.querySearch.page = 1;
      this.onGetRoomLog();
    },

    /* <!--@--> (changePage): Handle change pagination ------------------------------------------------------------------------- */
    changePage(e) {
      this.querySearch.page = e;
      this.onGetRoomLog();
    },

    /* <!--!--> Fetch: GET /manage-room/log (onGetRoomLog) ------------------------------------------------------------------------- */
    async onGetRoomLog() {
      this.startLoading();
      try {
        const response = await apiService.get(
          `manage-room/log?
          page=${this.querySearch.page}&
          size=${this.querySearch.size}&
          start_date=${this.querySearch.start_date}&
          end_date=${this.querySearch.end_date}`
        );
        console.warn('onGetRoomLog',response);
        console.warn('querySearch',this.querySearch);
        this.endLoading();
        const status = response.data.code;
        if (status === STATUS.SUCCESS) {
          this.list = response.data.data.rooms;
          this.pagination.total = response.data.data.pagination.total;
          this.pagination.perPage = response.data.data.pagination.per_page;
        } else if (status === STATUS.NO_CONTENT) {
          this.list = [];
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error.data.message);
      }
    },
    /* <!--!--> Fetch: GET /manage-room/log/export (onGetRoomLog) ------------------------------------------------------------------------- */
    async exportCsvRoomLog() {
      this.startLoading();
      try {
        var data = await apiService.get(
          `manage-room/log/export?
          page=${this.querySearch.page}&
          size=${this.querySearch.size}&
          start_date=${this.querySearch.start_date}&
          end_date=${this.querySearch.end_date}`
        );
        this.endLoading()
          var BOM = "\uFEFF"
          data = BOM + data.data;
          const blob = new Blob([data], { type: 'text/csv' });
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          const date = this.formatDate(new Date(), "「yyyy年MM月DD日」");
          downloadLink.download = `CHAIN_ルーム_過去ログ${date}`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
      } catch (error) {
        this.endLoading()
        this.notifyError(error.data.message)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.row-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  margin-bottom: 15px;
}

.set-border {
  border-left: 1px solid rgb(193, 193, 193);
  border-right: 1px solid rgb(193, 193, 193);
}

.flatpickr-icon {
  position: absolute;
  top: 6px;
  left: 9px;
}
.btn-search {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.pagination {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end !important;
  margin: 10px 7px;
}

.list-size {
  min-width: 160px;
}

.table-limit-height {
  max-height: 730px;
  min-height: 730px;
  margin-right: 14px;
  margin-left: 14px;
  padding: 0;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(163, 163, 163);
  }
}
</style>
